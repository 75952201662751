
.calender-search-home{
    background: #fff;
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition:  0.3s;
  }

    /* Define la animación fadeIn usando @keyframes */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

  
  .rdrMonth{
    width: 100% !;
  }
 


  .border-calender{
    border: solid 1px orange;
  }
  
  .hover-punter{
    cursor: pointer;
    padding:8px;
    transition:  0.3s;
    cursor: pointer ;
   z-index: 10;
  }
  
  .hover-punter:hover{
  
    cursor: pointer ;

    z-index: 10;
  }
  
  
  .Button-Search{
  
    cursor: pointer;
    border-radius: 20px;
    justify-content: center;
 
    
  }
  
  .calender-search-home {
    background: #fff;
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition:  0.3s;
  }

   
  .calender-search-Acoomodation {
    background: #fff;
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition:  0.3s;
  }
  
  .rdrMonth{
    width: 100% !;
  }
  


  .calender-search-Acoomodation {
    background: #fff;
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition:  0.3s;
  }
  
  .rdrMonth{
    width: 100% !;
  }
  



  .calender-search-home{
    border-radius: 2.25rem;
    box-shadow: 17px 20px 40px #00000036 !important;
    font-size: 16px !important;
    left: 0;
    margin: auto;
    position: absolute;
    right: 186px;
    top: 645px;
    transition: .3s;
    -webkit-user-select: none;
    user-select: none;
    width: 778px;
    z-index: 1000;
    transition:  0.3s;
    animation: fadeIn 0.3s ease forwards;
  }


  

  /* Para pantallas medianas (tablets) */
@media (max-width: 1020px) {
  .calender-search-home {
    width: 98%;
    top: 100px;
    left: 0px;
    right: 0px;
  }
}

/* Para pantallas pequeñas (móviles) */
@media (max-width: 640px) {
  .calender-search-home {
    width: 98%;
    top: 100px;
    left: 0px;
    right: 0px;
    box-shadow: 10px 15px 30px rgba(0, 0, 0, .21) !important; /* Ajusta la sombra */
  }
} 
  
  .border-header{
    border-bottom: 1px solid rgb(224, 224, 224);  
  }
  
  .rdrStartEdge{
    background-color: #b0a180;

  }

  .rdrEndEdge{
    background-color: #b0a180;
  
  }

