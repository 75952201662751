@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  background-color: #bebba9 !important; 
}
.container {
  height: 100vh;
  overflow: scroll; /* Permite el scroll */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE y Edge */
}

.container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.content {
  height: 200vh; /* Más alto que el viewport para probar el scroll */
  background: linear-gradient(#e66465, #9198e5);
}

.App {
  text-align: center;
}

.font-textKiinBrewfine {
  font-family: TRJN DaVinci Text, sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.live-whata{
  z-index: 1000;
  border-radius: 50%;
  background: linear-gradient(45deg, rgb(23, 201, 100), rgb(43, 255, 152));
  box-shadow: 0 0 10px rgba(43, 255, 152, 0.8), 0 0 20px rgba(43, 255, 152, 0.6),
    0 0 30px rgba(43, 255, 152, 0.4), 0 0 40px rgba(43, 255, 152, 0.2);
  animation: pulse 1.5s ease-in-out infinite, flicker 2s linear infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.border-chose{
  background: #817c70;
  width: 47%;
  border-radius: 44px;
  justify-content: center;
}

.border-confirme{

  border-radius: 44px;
  width: 47%;
  justify-content: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.accomodation{
  border-radius:35px;
}

.animation{
  animation: fadeIn 0.8s ease forwards;
}


.spinner {
width: 36px;
height: 36px;

border:5px solid #fffc;
border-top-color:#09f;
border-radius: 100%;
animation: spin 1s infinite;
} 

@keyframes spin {
  to {
    transform:  rotate(360deg);
  }
}


/*
‹div
class="spinner"
role="status"
aria-live="polite"
aria-label="Cargando"
‹/div>
*/
